* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  background-color: white;
}

.css-i1r5v {
  background-color: transparent !important;
}

/* .highlighted-date {
  background-color: #006edc !important;
  border-radius: 6px;
  font-weight: bold;
  color: #FFFFFF;
} */
.highlighted-date {
  position: relative;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

/* Styles for past dates */
.highlighted-date-red .dot {
  background-color: #dc0000;
  /* Red for past dates */
}

/* Styles for future dates */
.highlighted-date-green .dot {
  background-color: #00bfae;
  /* Green for future dates */
}

.react-calendar {
  width: 90% !important;
  max-width: 90% !important;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: solid transparent 1px !important;
}